import { authRedirect } from "@/utils/auth";

import { ERROR_TYPES } from "@/constants";

export const publicRoutes = [
  {
    path: "/not-found",
    name: "404",
    props: { errorType: ERROR_TYPES.notFound },
    meta: {
      isPublic: true,
    },
    component: () => import(/* webpackChunkName: "core-routes" */ "@/views/Error/Error.vue"),
  },
  {
    path: "/error",
    name: "error",
    props: { errorType: ERROR_TYPES.serverError },
    meta: {
      isPublic: true,
    },
    component: () => import(/* webpackChunkName: "core-routes" */ "@/views/Error/Error.vue"),
  },
  {
    path: "/login",
    name: "login",
    meta: {
      isPublic: true,
    },
    beforeEnter: (to, from, next) => {
      authRedirect(to.params.redirect);
      next(false);
    },
  },
  {
    path: "*",
    meta: {
      isPublic: true,
    },
    redirect: { name: "404" },
  },
];
