import { environmentVariable } from "@/utils/environments";

export function authRedirect(redirect_uri, lang) {
  window.location.assign(authLink("sign-in", redirect_uri, lang));
}

export function authLink(slug, redirect_uri, lang) {
  const locale = lang || localStorage.getItem("locale") || "ar";
  const authUrl = environmentVariable("VUE_APP_AUTH_URL");
  return `${authUrl}/${locale}${slug && "/" + slug}?redirect_uri=${
    redirect_uri ? window.location.origin + redirect_uri : window.location.href
  }`;
}
