import Api from "@/api";
import * as types from "@/types/store";

export default {
  [types.ACTION_PAYMENT_STATUS_GET]: async function({ commit }, transactionId) {
    try {
      commit(types.MUTATION_PAYMENT_STATUS_FETCH, { loading: true, error: false });

      const response = await Api.payment.getPaymentStatus(transactionId);

      commit(types.MUTATION_PAYMENT_STATUS_STATUS_SET, response);
      commit(types.MUTATION_PAYMENT_STATUS_FETCH, { loading: false, error: false });

      return response;
    } catch (error) {
      const err = error && error.response && error.response.data;
      commit(types.MUTATION_PAYMENT_STATUS_FETCH, { loading: false, error: true });

      return { error: err || error };
    }
  },
};
