import { mapActions, mapState } from "vuex";
import { pubSub } from "@takamol/agnostic-qiwa-core/pubsub";
import MicrofrontendApp from "@takamol/agnostic-qiwa-core/vue2Lib";

import { ACTION_LOCALE_SET } from "@/types/store";
import { normalizeLocalStorageLanguage } from "@/utils/normalizeLocalStorageLanguage";

export default {
  name: "Layout",
  components: {
    MicrofrontendApp,
  },
  data() {
    return {
      microFrontendSubscriptionId: null,
    };
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
      language: state => state.i18n.language,
    }),
  },
  mounted() {
    this.microFrontendSubscriptionId = pubSub.subscribe(event => {
      if (event.type === "qiwa-core/changeLocale") {
        const locale = event.payload.locale;
        const normalizedLocale = normalizeLocalStorageLanguage(locale);

        this.changeLang(normalizedLocale);
      }
    });
  },
  beforeDestroy() {
    if (this.microFrontendSubscriptionId) {
      pubSub.unsubscribe(this.microFrontendSubscriptionId);
    }
  },
  methods: {
    ...mapActions({
      setLocale: ACTION_LOCALE_SET,
    }),
    async changeLang(locale) {
      await this.setLocale(locale);
    },
  },
};
