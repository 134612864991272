<template>
  <div id="app">
    <!--<div class="q-loading-overlay" v-if="loading">
      <div class="q-loading-background"></div>
      <q-spinner />
    </div>-->
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  metaInfo() {
    return {
      title: this.$t("CORE.SITE_TITLE"),
      htmlAttrs: {
        lang: this.language,
        dir: this.language === "ar" ? "rtl" : "ltr",
      },
    };
  },
  computed: {
    ...mapState({
      language: state => state.i18n.language,
    }),
  },
};
</script>

<style lang="scss">
html {
  background: $color-background;
}

body {
  font-family: $font-default-ar, Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
  height: 100%;

  [dir="rtl"] & {
    direction: rtl;
  }
}
</style>
