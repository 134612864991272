import { requestConfig } from "@/utils/environments";

async function run() {
  await requestConfig("/config.json");

  const { vm } = require("./app.js");

  vm.$mount("#app");
}

run();
