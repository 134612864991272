import Api from "@/api";
import * as types from "@/types/store";

export default {
  [types.ACTION_MAKE_PAYMENT_GET]: async function({ commit }, { id }) {
    try {
      commit(types.MUTATION_MAKE_PAYMENT_FETCH, { loading: true, error: false });
      commit(types.MUTATION_MAKE_PAYMENT_SET, {});

      const { data: payment } = await Api.payment.makePayment(id);

      commit(types.MUTATION_MAKE_PAYMENT_FETCH, { loading: false, error: false });
      commit(types.MUTATION_MAKE_PAYMENT_SET, payment);

      return payment;
    } catch (error) {
      const err = error && error.response && error.response.data;
      commit(types.MUTATION_MAKE_PAYMENT_FETCH, { loading: false, error: true });

      return { error: err || error };
    }
  },
};
