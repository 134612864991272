import { environmentVariable } from "@/utils/environments";
import { QIWA_STATUS } from "@/constants";

export const qiwaStatus = loading => {
  if (loading) {
    return QIWA_STATUS.loading;
  }
  return QIWA_STATUS.success;
};

export const qiwaLibraryUrls = {
  productRegistryApi: environmentVariable("VUE_APP_REGISTRY_API_URL"),
  notificationsApi: environmentVariable("VUE_APP_NOTIFICATIONS_API_URL"),
  authApi: environmentVariable("VUE_APP_AUTH_API_URL"),
  authSpa: environmentVariable("VUE_APP_AUTH_URL"),
  dashboardSpa: environmentVariable("VUE_APP_DASHBOARD_SPA_URL"),
  landingSpa: environmentVariable("VUE_APP_QIWA_URL"),
  lmi: environmentVariable("VUE_APP_LMI_URL"),
  policies: environmentVariable("VUE_APP_POLICIES_URL"),
  knowledge: environmentVariable("VUE_APP_KNOWLEDGE_URL"),
};
