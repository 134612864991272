import Api from "@/api";
import * as types from "@/types/store";

export default {
  [types.ACTION_PAYMENT_PRICE_GET]: async function({ commit, dispatch }, transactionId) {
    try {
      commit(types.MUTATION_PAYMENT_PRICE_FETCH, { loading: true, error: false });
      commit(types.MUTATION_PAYMENT_PRICE_SET, {});

      const { data: priceDetails } = await Api.payment.getPaymentPrice(transactionId);

      const { price, currency, lang } = priceDetails;

      await dispatch(types.ACTION_LOCALE_SET, lang.toLowerCase());

      commit(types.MUTATION_PAYMENT_PRICE_FETCH, { loading: false, error: false });
      commit(types.MUTATION_PAYMENT_PRICE_SET, { price, currency });

      return { price, currency };
    } catch (error) {
      const err = error && error.response && error.response.data;
      commit(types.MUTATION_PAYMENT_PRICE_FETCH, { loading: false, error: true });

      return { error: err || error };
    }
  },
};
