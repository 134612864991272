import Api from "@/api";
import * as types from "@/types/store";

import { PROMISE_STATUS } from "@/constants";
import { reflect } from "@/utils/promises";

export default {
  [types.ACTION_PAYMENT_RESULT_GET]: async function({ commit }, transactionId) {
    try {
      commit(types.MUTATION_PAYMENT_RESULT_FETCH, { loading: true, error: false });

      const promises = [Api.payment.getRedirectUrl(transactionId), Api.payment.getPaymentStatus(transactionId)];

      const [redirectResponse, statusResponse] = await Promise.all(promises.map(reflect));

      if (redirectResponse.status === "rejected") {
        throw Error(redirectResponse.reason);
      }

      const { data: redirect } = redirectResponse;
      const { data: status } = statusResponse;

      if (statusResponse.status === PROMISE_STATUS.rejected) {
        commit(types.MUTATION_PAYMENT_RESULT_FETCH, { loading: false, error: true });
      } else {
        commit(types.MUTATION_PAYMENT_RESULT_FETCH, { loading: false, error: false });
      }

      commit(types.MUTATION_PAYMENT_RESULT_STATUS_SET, status);
      commit(types.MUTATION_PAYMENT_RESULT_REDIRECT_SET, redirect);

      return { redirect, status };
    } catch (error) {
      commit(types.MUTATION_PAYMENT_RESULT_FETCH, { loading: false, error: true });

      return { error };
    }
  },
};
