import Message from "@/components/Message/Message.vue";

import { environmentVariable } from "@/utils/environments";

export default {
  name: "Dashboard",
  components: {
    Message,
  },
  methods: {
    redirectToService() {
      const redirectUrl = environmentVariable("VUE_APP_QIWA_URL");
      window.location.assign(redirectUrl);
    },
  },
};
