/*eslint no-console: ["off"] */
import { Notifier } from "@airbrake/browser";

import { getAirbrakeConfig, isDevelop } from "@/utils/environments";

/**
 * @description Logger class
 * This is responsible for logging of all kind of stuff in the application
 * Default, we are using the console api for logging and this provides the basic level of logging such as
 * you can use the available method of console in developement but in production these will be replaced with empty methods
 */
class Logger {
  _airbrake = null;

  /**
   * @constructor AppLogger
   */
  constructor() {
    const { projectId, projectKey, environment } = getAirbrakeConfig();

    if (projectId && projectKey && environment) {
      this._airbrake = new Notifier({ projectId, projectKey, environment });
    } else {
      this._airbrake = null;
    }

    /** Initializing the configuration of logger */
    this.initLogger();
  }

  /**
   * @description Initializing the configuration such as if environment is production then all log method will be replaced with empty methods
   */
  initLogger() {
    /** Checking the environment */
    if (isDevelop()) {
      this.log = console.log.bind(console);
      this.debug = console.debug.bind(console);
      this.info = console.info.bind(console);
      this.warn = console.warn.bind(console);
      this.error = console.error.bind(console);
    } else {
      /** In case of production replace the functions definition */
      this.log = this.debug = this.info = this.warn = this.error = () => {};
    }
  }

  /**
   * @description Method responsible for logging the Vue specific errors to log server
   */
  logVueError({ err }) {
    const message = `[Vue error] ${err.message}, ${navigator.userAgent}`;
    const stack = err.stack;

    if (this._airbrake) {
      this._airbrake.notify({
        error: { message, stack },
      });
    }

    this.error(`${message} ${stack}`);
  }

  /**
   * @description Method responsible for logging the Vue warnings to log server
   */
  logVueWarning({ msg, trace: stack }) {
    const message = `[Vue warn] ${msg}`;

    if (this._airbrake) {
      this._airbrake.notify({
        error: { message, stack },
      });
    }

    this.error(`${message} ${stack}`);
  }

  /**
   * @description Method responsible for logging the API errors to log server
   */
  logApiError(err) {
    const {
      request: { responseURL },
      status: statusCode,
      headers,
      data: response,
    } = err.response;

    const message = `[API Error] ${responseURL} ${err.message}`;
    const apiUrl = `[${err.config.method.toUpperCase()}] ${responseURL}`;
    const xCorrelationId = headers["x-correlation-id"] || "";
    const xCorrelationLogs = headers["x-correlation-logs"] || "";

    if (this._airbrake) {
      this._airbrake.notify({
        error: { message, noStack: true },
        params: { apiUrl, statusCode, xCorrelationId, xCorrelationLogs, response },
      });
    }
  }

  /**
   * @description Method responsible for logging the other errors to log server
   */
  logError({ message, error }) {
    const msg = `${message} , ${navigator.userAgent}`;

    if (this._airbrake) {
      this._airbrake.notify({
        error: msg,
        params: { info: error },
      });
    }

    this.error(`${msg}`);
  }
}

/** Creating and exporting the instance of logger */
const logger = new Logger();

export { logger };
