import { exactValueValidator } from "@/utils/props-validators";

export default {
  name: "Message",
  props: {
    type: {
      type: String,
      validator: exactValueValidator(["success", "danger", "info", "warning"]),
      required: true,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
    },
  },
};
