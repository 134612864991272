import { logger } from "@/services/logger";

export const addLogger = Vue => {
  Vue.config.silent = false;

  Vue.config.errorHandler = err => {
    logger.logVueError({ err });
  };

  Vue.config.warnHandler = (msg, _, trace) => {
    logger.logVueWarning({ msg, trace });
  };

  window.onerror = function(message, _, __, ___, error) {
    logger.logError({ message, error });
  };
};
