import { loadLocaleAsync } from "@/i18n";

import * as types from "@/types/store";

export default {
  [types.ACTION_LOCALE_SET]: async function({ commit, dispatch }, locale) {
    try {
      dispatch(types.ACTION_GLOBAL_LOADER_SET, true);

      await loadLocaleAsync(locale);

      commit(types.MUTATION_LOCALE_SET, locale);
      dispatch(types.ACTION_GLOBAL_LOADER_SET, false);
      return locale;
    } catch (error) {
      dispatch(types.ACTION_GLOBAL_LOADER_SET, false);
      return error;
    }
  },
};
