import * as types from "@/types/store";

export default {
  [types.MUTATION_CREATE_SADAD_FETCH](state, { loading, error }) {
    state.loading = loading;
    state.error = error;
  },
  [types.MUTATION_SADAD_STATUS_FETCH](state, { loading, error }) {
    state.loading = loading;
    state.error = error;
  },
  [types.MUTATION_SADAD_STATUS_SET](state, { isAvailable }) {
    state.isAvailable = isAvailable;
  },
};
