import Vue from "vue";
import VueI18n from "vue-i18n";

import messagesAr from "@/i18n/ar.json";
import { normalizeLocalStorageLanguage } from "@/utils/normalizeLocalStorageLanguage";

Vue.use(VueI18n);

export const i18n = new VueI18n({
  locale: "ar",
  fallbackLocale: "ar",
  messages: {
    ar: messagesAr,
  },
});

const loadedLocales = ["ar"];
const PAYMENT_GATEWAY_STORAGE_KEY = "paymentGatewayLocale";

function setI18nLocale(locale) {
  i18n.locale = locale;
  document.querySelector("html").setAttribute("locale", locale);
  return locale;
}

function setLocalStorageLocale(locale) {
  const currentLocale = localStorage.getItem(PAYMENT_GATEWAY_STORAGE_KEY);
  if (currentLocale !== locale) {
    try {
      localStorage.setItem(PAYMENT_GATEWAY_STORAGE_KEY, locale);
    } catch (e) {
      return e;
    }
  }
}

export async function loadLocaleAsync(locale) {
  const normalizedLocale = normalizeLocalStorageLanguage(locale);
  setLocalStorageLocale(normalizedLocale);
  if (i18n.locale !== normalizedLocale) {
    if (!loadedLocales.includes(normalizedLocale)) {
      const msgs = await import(/* webpackChunkName: "locale-[request]" */ `@/i18n/${normalizedLocale}.json`);
      i18n.setLocaleMessage(normalizedLocale, msgs.default);
      loadedLocales.push(normalizedLocale);
      return setI18nLocale(normalizedLocale);
    }
    return Promise.resolve(setI18nLocale(normalizedLocale));
  }
  return Promise.resolve(normalizedLocale);
}
