import Dashboard from "@/views/Dashboard/Dashboard.vue";
import Layout from "@/layouts/Layout/Layout.vue";

export const authRoutes = [
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "/",
        name: "dashboard",
        component: Dashboard,
      },
      {
        path: "/payment",
        name: "payment",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/SelectPayment/SelectPayment.vue"),
      },
      {
        path: "/paymentCheckout",
        name: "paymentCheckout",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Checkout/Checkout.vue"),
      },
      {
        path: "/:transactionId/result",
        name: "result",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Result/Result.vue"),
      },
    ],
  },
];
