import * as types from "@/types/store";

export default {
  [types.MUTATION_PAYMENT_STATUS_FETCH](state, { loading, error }) {
    state.loading = loading;
    state.error = error;
  },
  [types.MUTATION_PAYMENT_STATUS_STATUS_SET](state, payload) {
    state.status = payload;
  },
};
