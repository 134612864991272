import { PROMISE_STATUS } from "@/constants";

export const reflect = promise => {
  return promise.then(
    result => {
      const { data } = result;
      return { status: PROMISE_STATUS.fulfilled, data };
    },
    error => {
      const data = error?.response?.data || error;
      return { status: PROMISE_STATUS.rejected, data };
    },
  );
};
