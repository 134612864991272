const hasValue = (arr, value) => {
  return arr.every(v => typeof v === "string") && arr.includes(value);
};

export const exactValueValidator = matchValue => value => {
  if (typeof value !== "string") {
    return false;
  }

  if (typeof matchValue === "string") {
    return matchValue === value;
  }

  if (Array.isArray(matchValue)) {
    return hasValue(matchValue, value);
  }

  if (typeof matchValue === "object") {
    const values = Object.values(matchValue);
    return hasValue(values, value);
  }

  return false;
};
