import * as types from "@/types/store";

export default {
  [types.MUTATION_PAYMENT_PRICE_FETCH](state, { loading, error }) {
    state.loading = loading;
    state.error = error;
  },
  [types.MUTATION_PAYMENT_PRICE_SET](state, payload) {
    state.details = payload;
  },
};
