import Api from "@/api";
import * as types from "@/types/store";

export default {
  [types.ACTION_GET_SADAD_STATUS]: async function({ commit }, id) {
    try {
      commit(types.MUTATION_SADAD_STATUS_FETCH, { loading: true, error: false });
      commit(types.MUTATION_SADAD_STATUS_SET, false);

      const { data } = await Api.sadad.getSadadStatus(id);
      const status = data;

      commit(types.MUTATION_SADAD_STATUS_FETCH, { loading: false, error: false });
      commit(types.MUTATION_SADAD_STATUS_SET, status);

      return { status };
    } catch (error) {
      const err = error && error.response && error.response.data;
      commit(types.MUTATION_SADAD_STATUS_FETCH, { loading: false, error: true });

      return { error: err || error };
    }
  },
  [types.ACTION_CREATE_SADAD]: async function({ commit }, id) {
    try {
      commit(types.MUTATION_CREATE_SADAD_FETCH, { loading: true, error: false });

      const { data } = await Api.sadad.createSadad(id);
      const { "gate-url": gateUrl } = data;

      commit(types.MUTATION_CREATE_SADAD_FETCH, { loading: false, error: false });

      return gateUrl;
    } catch (error) {
      const err = error && error.response && error.response.data;
      commit(types.MUTATION_CREATE_SADAD_FETCH, { loading: false, error: true });

      return { error: err || error };
    }
  },
};
