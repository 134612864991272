import { pgApi } from "../config";

export function makePayment(transactionId) {
  return pgApi.get(`payment/make/${transactionId}`);
}

export function getRedirectUrl(transactionId) {
  return pgApi.get(`payment/redirect/${transactionId}`);
}

export function getPaymentStatus(transactionId) {
  return pgApi.get(`payment/status/${transactionId}`);
}

export function getPaymentPrice(transactionId) {
  return pgApi.get(`payment/price/${transactionId}`);
}
