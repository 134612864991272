import axios from "axios";

import { ENVIRONMENT } from "@/constants";

let runtimeConfig = {};

export async function requestConfig(configJson) {
  try {
    const config = await axios.get(configJson);
    runtimeConfig = config.data;
  } catch (e) {
    console.warn("Could not find config.json file.");
  }
}

export const environmentVariable = code => {
  return runtimeConfig[code] || process.env[code] || "";
};

export function isDevelop() {
  return environmentVariable("NODE_ENV") === "development";
}

export function isVueAppLocal() {
  return environmentVariable("VUE_APP_ENVIRONMENT_NAME") === ENVIRONMENT.local;
}

export function isVueAppDevelop() {
  return environmentVariable("VUE_APP_ENVIRONMENT_NAME") === ENVIRONMENT.develop;
}

export function isVueAppStage() {
  return environmentVariable("VUE_APP_ENVIRONMENT_NAME") === ENVIRONMENT.stage;
}

export function getAirbrakeConfig() {
  const projectId = Number.parseInt(environmentVariable("VUE_APP_AIRBRAKE_ID"), 10) || null;
  const projectKey = environmentVariable("VUE_APP_AIRBRAKE_KEY");
  const environment = environmentVariable("VUE_APP_ENVIRONMENT_NAME");

  return { projectId, projectKey, environment };
}
