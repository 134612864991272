import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend("json", {
  validate: value => {
    try {
      JSON.parse(value);
      return true;
    } catch (error) {
      return "The {_field_} field must be a valid JSON";
    }
  },
});

localize("en", en);

export { ValidationObserver, ValidationProvider };
